<template>
  <b-modal
    id="modal-form"
    :title="type === 'add' ? 'Agregar Rol' : 'Editar Rol'"
    no-close-on-backdrop
    hide-footer
  >
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-form-group class="col-md-12" label="Nombre:" label-for="name">
            <ValidationProvider name="Nombre" rules="required" v-slot="{ errors }">
              <b-form-input
                v-model="form.name"
                type="text"
                :class="errors.length > 0 ? ' is-invalid' : ''"
              />
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </b-form-group>
          <div class="col-md-12 w-100">
            <b-button type="submit" variant="primary" class="float-right">
              {{ type === "add" ? "Guardar" : "Actualizar" }}
            </b-button>
          </div>
        </b-row>
      </b-form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import api from '../../../services/api'
import { Toast } from '../../../mixins/toast'

export default {
  components: {},
  props: {
    type: {
      type: String,
      required: true,
      default: 'add'
    },
    data: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      form: {
        name: ''
      }
    }
  },
  watch: {
    data: function (newValue, oldValue) {
      this.form = newValue
    }
  },
  computed: {},
  methods: {
    onSubmit () {
      if (this.type === 'add') {
        this.saveData()
      } else {
        this.updateData()
      }
    },
    async saveData () {
      try {
        await api.post('/roles', this.form)
        this.form = {
          name: ''
        }
        this.$bvModal.hide('modal-form')
        this.$emit('getData')
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Error'
        })
      }
    },
    async updateData () {
      api
        .put(`/roles/${this.data.id}`, this.form)
        .then(() => {
          Toast.fire({
            icon: 'success',
            title: 'Actualizado Correctamente'
          })
          this.$bvModal.hide('modal-form')
          this.$emit('getData')
        })
        .catch(() => {
          Toast.fire({
            icon: 'error',
            title: 'Error'
          })
        })
    }
  }
}
</script>
