<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Roles y Permisos</h4>
          </template>
          <template v-slot:headerAction>
            <b-button
              v-user-can="'crear roles'"
              variant="primary"
              @click="showModal('add', { name: '' })"
            >
              Agregar
            </b-button>
          </template>
          <template v-slot:body>
            <DataTable
              v-if="roles"
              :data="roles"
              :columns="columns"
              :fields-search="['name']"
            >
              <template #rows="{ row, rowIndex }">
                <tr>
                  <th scope="row">{{ ++rowIndex }}</th>
                  <td>
                    {{ row.name }}
                  </td>
                  <td>
                    <b-button-group>
                      <b-button
                        v-user-can="'leer roles'"
                        variant="secondary"
                        size="sm"
                        @click="
                          $router.push({ name: 'role.detail', params: { id: row.id } })
                        "
                      >
                        <i class="ri-file-list-line m-0"></i>
                      </b-button>
                      <b-button
                        v-user-can="'actualizar roles'"
                        variant="warning"
                        size="sm"
                        @click="showModal('edit', row)"
                      >
                        <i class="ri-ball-pen-fill m-0"></i>
                      </b-button>
                      <b-button
                        v-user-can="'eliminar roles'"
                        variant="danger"
                        size="sm"
                        @click="destroy(row.id)"
                      >
                        <i class="ri-delete-bin-line m-0"></i>
                      </b-button>
                    </b-button-group>
                  </td>
                </tr>
              </template>
            </DataTable>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <ModalForm @getData="getData" :type="type" :data="data" />
  </b-container>
</template>

<script>
import { Toast } from './../../../mixins/toast'
import { xray } from '../../../config/pluginInit'
import api from './../../../services/api'
import DataTable from './../../../components/DataTable.vue'
import ModalForm from './ModalForm.vue'
import Swal from 'sweetalert2'

export default {
  components: {
    DataTable,
    ModalForm
  },
  mounted () {
    xray.index()
    this.getData()
  },
  computed: {},
  methods: {
    async getData () {
      try {
        const { data } = await api.get(`/roles`)
        this.roles = data.data
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Error'
        })
      }
    },
    showModal (type, data) {
      this.data = {
        id: data.id,
        name: data.name
      }
      this.type = type
      this.$bvModal.show('modal-form')
    },
    destroy (id) {
      Swal.fire({
        icon: 'warning',
        title: 'Seguro de eliminar',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .delete(`/roles/${id}`)
            .then((res) => {
              if (res.status === 200) {
                Toast.fire({
                  icon: 'success',
                  title: 'Eliminado correctamente'
                })
                this.getData()
              }
            })
            .catch((error) => {
              Toast.fire({
                icon: 'error',
                title: error.message
              })
            })
        }
      })
    }
  },
  data () {
    return {
      data: {},
      type: 'add',
      roles: [],
      columns: [
        {
          name: 'Nombre',
          field: 'name',
          sort: ''
        },
        {
          name: 'Acciones',
          field: 'actions',
          sort: ''
        }
      ]
    }
  }
}
</script>
